import React from 'react'

import Card from '../components/card'
import Layout from '../components/layout'
import SEO from '../components/seo'

import expenseBookImg from '../images/work/expensebook.png';
import mag8ballImg from '../images/work/magic8ball.png';
import reactRgbGame from '../images/work/react-rgbgame.png';

const Work = () => (
  <Layout>
    <SEO title="Work" />
    <h1>Work</h1>

    <Card 
    	cardTitle="ExpenseBook"
      cardImage={expenseBookImg}
      cardDescription={<p>A fullstack (MERN) expenses web app built with React and data is stored in MongoDB. Completed with RESTful APIs and use of CRUD.<br /><br />While learning React, I wanted to experiment with MongoDB as a database and researched how to better secure an app using a token-based login system. Login credentials are salted and hashed for extra security.</p>}
      demoLink="https://react-expensebook.herokuapp.com/"
    	githubLink="https://github.com/minteapixel/mern-expensebook-mtp/"
    />

    <Card 
    	cardTitle="Magic8ball"
      cardImage={mag8ballImg}
      cardDescription={<p>Magic 8 ball game coded in Javascript and Sass compiled with Webpack.<br /><br />This project was created to learn more on how to configure Webpack and work with Sass.</p>}
      demoLink="https://minteapixel.github.io/magic8ball/"
    	githubLink="https://github.com/minteapixel/magic8ball"
    />

    <Card 
    	cardTitle="RGB Game - React"
      cardImage={reactRgbGame}
      cardDescription={<p>A simple color guessing game rebuilt using React. Features 4 different difficulty modes. <br /><br />	I had originally coded  RGB game in Javascript and decided to use my React skills by re-coding the game using the React framework. I also added the infernal mode in which the squares' colors are much closer along the spectrum, making it extra difficult to find the right color.</p>}
      demoLink="https://rgbgame-react-mtp.herokuapp.com/"
    	githubLink="https://github.com/minteapixel/react-rgb-game"
    />
  </Layout>
)

export default Work
