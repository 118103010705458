import React from 'react'

const Card = ({ cardTitle, cardImage, cardDescription, githubLink, demoLink }) => (
	<div className="card">
		<h2>{cardTitle}</h2>
		<img className="img" src={cardImage} alt={cardTitle} />
		{cardDescription}
		<div className="card-button-box">
			<a className="button button--secondary" href={githubLink} target="_blank" rel="noopener noreferrer">Source Code</a>
			<a className="button button--primary" href={demoLink}>Demo</a>
		</div>
	</div>
);

export default Card